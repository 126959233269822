import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'; // Create this file for additional custom styles if needed

const Footer = () => {
  return (
    <footer className="footer py-3">
      <div className="footer-container">
        <div className="row">
          <div className="col-12 col-md-6 text-center text-md-start mb-1 mb-md-0">
            In The Room, Inc. © 2024
          </div>
          <div className="col-12 col-md-6 text-center text-md-end">
            {window.location.pathname !== '/' ? <a href="/" className="d-inline mx-2">Home</a> : ''}
            {window.location.pathname !== '/blog' ? <a href="/blog" className="d-inline mx-2">Blog</a> : ''}
            <a href="https://www.ycombinator.com/companies/in-the-room" target='_blank' rel='noreferrer' className="d-inline mx-2">Investors</a>
            {window.location.pathname !== '/privacy-policy' ? <a href="/privacy-policy" className="d-inline mx-2">Privacy Policy</a> : ''}
            {window.location.pathname !== '/terms' ? <a href="/terms" className="d-inline mx-2">Terms</a> : ''}
            <a
              href="mailto:hello@intheroom.social"
              onClick={() => (window.location = 'mailto:hello@intheroom.social')}
              className="d-inline mx-2"
            >Contact</a>
            <span className="mx-1">|</span>
            <a href="https://m.intheroom.social/sign-in" className="d-inline mx-2">Sign In</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
