import React from "react";
// import Header from '../components/Header/Header'
import Meta from '../components/Meta'

const PrivacyPolicy = () => {
  return (
  <>
    <Meta
      title="In The Room | Privacy Policy"
      url="https://www.intheroom.social/privacy-policy"
    />
    <div>
      {/* <Header /> */}
      <div className="text-container my-5 px-5 px-md-0">
      <p className="text-center mb-0"><small>June 19, 2024</small></p>
      <h1 className="text-center mb-5">
          <b>Privacy Policy</b>
        </h1>

        <p>
          <b>Purpose of the Privacy Policy:</b> This Privacy Policy aims to
          inform you about how "In the Room" ("we," "our," or "us") collects,
          uses, discloses, and safeguards your personal information when you use
          our mobile application ("the App"). Your privacy is critically
          important to us, and we are committed to protecting it. This document
          also outlines your rights regarding your personal information and how
          you can exercise those rights.
        </p>

        <p>
          <b>Overview of the App's Functionality:</b> "In the Room" is designed
          to help users meet and connect with others at events such as
          conferences, meetups, and venues like bars, clubs, and lounges. The
          App provides real-time geolocation features to show users others in
          their immediate vicinity, integrates with social media profiles
          (Instagram, LinkedIn), and allows sharing of user profiles with others
          in the area.
        </p>

        <h2>Information We Collect</h2>

        <p>
          <b>Personal Information:</b> When you use the App, we may collect and
          store personal information that you provide directly to us, including:
        </p>
        <ul>
          <li>
            <b>Name:</b> To identify you within the App.
          </li>
          <li>
            <b>Email Address:</b> For account creation, authentication, and
            communication.
          </li>
          <li>
            <b>Social Media Profiles:</b> Links to your Instagram, LinkedIn, or
            other social media profiles to facilitate social networking
            features.
          </li>
        </ul>

        <p>
          <b>Geolocation Data:</b> To provide location-based services, we
          collect precise geolocation data from your device. This data helps us
          show your proximity to other users and relevant events.
        </p>

        <p>
          <b>Usage Data:</b> We collect data about your interactions with the
          App, including:
        </p>
        <ul>
          <li>
            <b>App Interaction Data:</b> Information on how you use the App,
            such as features you interact with and time spent on the App.
          </li>
          <li>
            <b>Preferences:</b> Your settings and preferences within the App.
          </li>
        </ul>

        <h2>How We Use Your Information</h2>

        <p>
          <b>Providing and Improving the App's Services:</b> We use your
          information to:
        </p>
        <ul>
          <li>Operate and maintain the App.</li>
          <li>Enhance and personalize your user experience.</li>
          <li>Develop new features and services.</li>
        </ul>

        <p>
          <b>Enhancing User Experience:</b> Your information helps us:
        </p>
        <ul>
          <li>Provide tailored content and recommendations.</li>
          <li>Enable personalized networking opportunities.</li>
        </ul>

        <p>
          <b>Facilitating Connections and Networking:</b> We use your
          information to:
        </p>
        <ul>
          <li>
            Help you discover and connect with other users in your vicinity.
          </li>
          <li>Enable the sharing of profiles with others nearby.</li>
        </ul>

        <p>
          <b>Sending Notifications and Updates:</b> We may send you:
        </p>
        <ul>
          <li>App updates and feature announcements.</li>
          <li>Event-related information and reminders.</li>
          <li>Security updates and important notifications.</li>
        </ul>

        <h2>Sharing Your Information</h2>

        <p>
          <b>With Other Users in Your Vicinity:</b> To facilitate connections,
          we may share your profile information, including name and social media
          links, with other users who are in your immediate vicinity.
        </p>

        <p>
          <b>With Third-Party Service Providers:</b> We may share your
          information with third-party service providers who assist us in:
        </p>
        <ul>
          <li>Operating and maintaining the App.</li>
          <li>Providing analytics and improving functionality.</li>
        </ul>

        <p>
          <b>Legal Requirements and Protection of Rights:</b> We may disclose
          your information if required by law or in good faith belief that such
          action is necessary to:
        </p>
        <ul>
          <li>Comply with legal obligations.</li>
          <li>Protect and defend our rights or property.</li>
          <li>
            Prevent or investigate possible wrongdoing related to the App.
          </li>
          <li>Ensure the personal safety of users or the public.</li>
        </ul>

        <h2>Data Security</h2>

        <p>
          <b>Measures to Protect User Data:</b> We implement a variety of
          security measures to ensure the safety of your personal information.
          These include:
        </p>
        <ul>
          <li>Secure data transmission using SSL/TLS encryption.</li>
          <li>Regular security assessments and updates.</li>
        </ul>

        <p>
          <b>Encryption and Secure Storage:</b> Your data is encrypted both in
          transit and at rest. We use secure servers and storage solutions to
          safeguard your information against unauthorized access.
        </p>

        <h2>Data Retention</h2>

        <p>
          <b>Duration of Data Storage:</b> We retain your personal information
          for as long as necessary to:
        </p>
        <ul>
          <li>Provide the services you have requested.</li>
          <li>Comply with legal obligations.</li>
          <li>Resolve disputes and enforce our agreements.</li>
        </ul>

        <p>
          <b>User Rights to Request Data Deletion:</b> You have the right to
          request the deletion of your personal information. To exercise this
          right, please contact us at the information provided below.
        </p>

        <h2>User Rights</h2>

        <p>
          <b>Access, Correction, and Deletion of Personal Information:</b> You
          have the right to:
        </p>
        <ul>
          <li>Access the personal information we hold about you.</li>
          <li>
            Request corrections to any inaccurate or incomplete information.
          </li>
          <li>Request the deletion of your personal information.</li>
        </ul>

        <p>
          <b>Opt-Out Options for Data Collection and Sharing:</b> You can opt
          out of certain data collection and sharing practices through:
        </p>
        <ul>
          <li>Your account settings within the App.</li>
          <li>
            Direct communication with us using the contact information below.
          </li>
        </ul>

        <h2>Cookies and Tracking Technologies</h2>

        <p>
          <b>Use of Cookies for App Functionality and Analytics:</b> We use
          cookies and similar tracking technologies to:
        </p>
        <ul>
          <li>Improve App functionality.</li>
          <li>Analyze user behavior and preferences.</li>
        </ul>

        <p>
          <b>User Options for Managing Cookies:</b> You can manage your cookie
          preferences through your device settings or browser settings. You may
          also contact us for further assistance.
        </p>

        <h2>Third-Party Links</h2>

        <p>
          <b>Links to Third-Party Websites and Services:</b> The App may contain
          links to third-party websites and services. These are provided for
          your convenience, and we are not responsible for the privacy practices
          or content of these third parties.
        </p>

        <p>
          <b>Disclaimer of Responsibility for Third-Party Content:</b> We do not
          endorse or assume responsibility for any third-party content or
          services linked through the App. We encourage you to review the
          privacy policies of any third-party sites you visit.
        </p>

        <h2>Children’s Privacy</h2>

        <p>
          <b>Age Restrictions for App Usage:</b> The App is not intended for use
          by individuals under the age of 16. We do not knowingly collect
          personal information from children under 16. If we become aware that
          we have inadvertently collected such information, we will take steps
          to delete it promptly.
        </p>

        <p>
          <b>Parental Consent Requirements:</b> If a parent or guardian becomes
          aware that their child has provided us with personal information
          without their consent, they should contact us immediately. We will
          take steps to remove such information and terminate the child's
          account.
        </p>

        <h2>Changes to This Privacy Policy</h2>

        <p>
          <b>Notification of Changes:</b> We may update this Privacy Policy from
          time to time to reflect changes in our practices or for other
          operational, legal, or regulatory reasons. We will notify you of any
          significant changes by posting the new policy on the App and updating
          the effective date.
        </p>

        <p>
          <b>Effective Date of the Privacy Policy:</b> This Privacy Policy is
          effective as of June 19, 2024. Your continued use of the App after the
          effective date constitutes your acceptance of the updated policy.
        </p>

        <h2>Contact Us</h2>

        <p>
          <b>Contact Information for Privacy-Related Inquiries:</b> If you have
          any questions or concerns about this Privacy Policy or our data
          practices, please contact us at:
        </p>
        <p>
          Email:{" "}
          <a href="mailto:hello@intheroom.social">hello@intheroom.social</a>
        </p>

        <p>
          By using "In the Room," you acknowledge that you have read and
          understood this Privacy Policy and agree to our data practices as
          described.
        </p>
      </div>
    </div>
  </>
  );
};

export default PrivacyPolicy;
