import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import {
  Alert,
  Button,
  Dropdown,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import {
  FaCheckCircle,
  FaQrcode,
  FaTimesCircle,
  FaSearch,
  FaSms,
  FaSpinner,
} from "react-icons/fa"; // Importing icons
import { QRCodeCanvas } from "qrcode.react"; // Library for generating QR codes
import { useLinkedIn } from "../context/linkedin-context";


const ContactForm = () => {
  const [error, setError] = useState("");
  const { linkedinURL, setLinkedinURL, formState, setFormState } =
    useLinkedIn();
  const [instagramStatus, setInstagramStatus] = useState(null); // To track Instagram validation status
  const [token, setToken] = useState(null); // To track Instagram validation status
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // For LinkedIn search
  const [searchResults, setSearchResults] = useState([]); // Search results
  const [searchLoading, setSearchLoading] = useState(false); // Loading state for search
  const [searchingIG, setSearchingIG] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false); // Show/hide QR code modal

  // Get params from url using react-router-dom
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const pushStatus = params.get('push');
  console.log('pushStatus', pushStatus);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLinkedin = async () => {
    try {
      const response = await fetch(
        "https://8tnulw7wii.execute-api.us-east-1.amazonaws.com/production/apify/get-user-linkedin",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            instagram_username: formState.instagram,
            linkedin_url: linkedinURL,
            phone_number: formState.phone,
          }),
        }
      );

      if (response) {
        console.log("Collected User Linkedin successfully");
      } else {
        setError("Failed to collect user linkedin. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
      console.error("Error submitting data:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleLinkedInSearch = async () => {
    if (!searchTerm) return;
    setSearchLoading(true);
    setSearchResults([]);
    try {
      const response = await fetch(
        "https://8tnulw7wii.execute-api.us-east-1.amazonaws.com/production/apify/search-linkedin-profiles",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ keyword: searchTerm }),
        }
      );
      const data = await response.json();
      setSearchResults(data || []);
    } catch (error) {
      console.error("Error searching LinkedIn profiles:", error);
      setSearchResults([]);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleInstagram = async () => {
    try {
      const response = await fetch(
        "https://8tnulw7wii.execute-api.us-east-1.amazonaws.com/production/apify/get-user-instagram",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            instagram_username: formState.instagram,
            linkedin_url: linkedinURL,
            phone_number: formState.phone,
          }),
        }
      );

      if (response) {
        console.log("Collected User Instagram successfully");
      } else {
        setError("Failed to collect user instagram. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
      console.error("Error submitting data:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectProfile = (url) => {
    setLinkedinURL(url);
    setSearchResults([]); // Clear search results after selection
    // setSearchTerm(""); // Reset search term
  };

  const handleSendSms = () => {
    const encodedURL = encodeURIComponent(
      `https://m.intheroom.social?t=${token}`
    );
    const encodedMsg = encodeURIComponent(
      "Welcome to In The Room\n\nClick the link to view who is around with you at Rock Steady right now "
    );
    window.location.href = `sms:${formState.phone}?body=${encodedMsg}\n\n${encodedURL}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form:", formState); // Debugging to ensure formState has the correct values

    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        "https://8tnulw7wii.execute-api.us-east-1.amazonaws.com/production/apify/create-user",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            instagram_username: formState.instagram,
            linkedin_url: linkedinURL,
            phone_number: formState.phone,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setToken(data.token);

        console.log("Data submitted successfully");
        handleInstagram();
        handleLinkedin();

        if (pushStatus === 'true') {
          // Go to url with token
          window.location.href = `https://m.intheroom.social?t=${data.token}`;
        }
      } else {
        setError("Failed to submit data. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
      console.error("Error submitting data:", err);
    } finally {
      setLoading(false);
    }
  };

  // Function to validate Instagram username
  const validateInstagram = async () => {
    if (!formState.instagram) return;
    setSearchingIG(true);
    try {
      const response = await fetch(
        "https://8tnulw7wii.execute-api.us-east-1.amazonaws.com/production/apify/validate-instagram",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ instagram_username: formState.instagram }),
        }
      );

      setSearchingIG(false);
      if (response.ok) {
        setInstagramStatus("success"); // Show #3cb8e7 icon
      } else {
        setInstagramStatus("error"); // Show red icon
      }
    } catch (err) {
      setSearchingIG(false);
      setInstagramStatus("error"); // Show red icon on error
      console.error("Instagram validation error:", err);
    }
  };

  return (
    <div className="mt-4 p-4 text-light rounded">
      <div className="mb-5">
        <h1
          className="mb-0 text-white"
          style={{
            fontSize: "2.3rem",
            fontWeight: "bolder",
            textAlign: "left",
          }}
        >
          Sign Up
        </h1>
        <p
          style={{
            opacity: 0.6,
            color: "white",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Fill out the form below to sign up.
        </p>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group
          controlId="formInstagram"
          className="mb-5 position-relative"
        >
          <Form.Label className="mb-0" style={style.formLabel}>
            Instagram Username
          </Form.Label>
          <div style={{ position: "relative" }}>
            <Form.Control
              type="text"
              name="instagram"
              placeholder="@username"
              onBlur={validateInstagram}
              onFocus={() => setInstagramStatus(null)}
              value={formState.instagram || ""} // Bind to formState
              onChange={handleInputChange} // Update formState on change
              className="custom-input bg-transparent text-light border-bottom border-0 rounded-0 px-0"
              style={{
                height: "60px",
                fontSize: "1.2rem",
                color: "white",
                opacity: 0.7,
                paddingRight: "40px", // Leave space for the icon
              }}
            />
            {/* Status Icon (Always Rendered to Avoid Jumping) */}
            <div
              className="position-absolute"
              style={{
                position: "absolute",
                right: "10px", // Align icon with the right edge of the input
                top: "50%", // Vertically center the icon
                transform: "translateY(-50%)", // Ensure proper vertical alignment
                opacity: instagramStatus || searchingIG ? 1 : 0, // Toggle visibility
                transition: "opacity 0.2s ease", // Smooth transition
                pointerEvents: "none", // Ignore clicks on the icon
              }}
            >
              {searchingIG ? (
                <FaSpinner
                  color="gray"
                  size={20}
                  style={{ animation: "spin 1.5s linear infinite" }}
                />
              ) : instagramStatus === "success" ? (
                <FaCheckCircle color="#3cb8e7" size={20} />
              ) : (
                <FaTimesCircle color="red" size={20} />
              )}
            </div>
          </div>
        </Form.Group>

        <Form.Group controlId="formLinkedIn" className="mb-5 position-relative">
          <Form.Label className="mb-0" style={style.formLabel}>
            LinkedIn Name
          </Form.Label>
          <div style={{ position: "relative" }}>
            <Form.Control
              type="text"
              placeholder="Search Using Your Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="custom-input bg-transparent text-light border-bottom border-0 rounded-0 px-0"
              style={{
                height: "60px",
                fontSize: "1.2rem",
                paddingRight: "40px",
              }}
            />
            <Button
              variant="link"
              className="text-light ms-2 rounded position-absolute"
              onClick={handleLinkedInSearch}
              disabled={searchLoading}
              style={{
                fontSize: "1.5rem",
                padding: "10px",
                right: "10px", // Fixed position relative to input
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              {searchLoading ? (
                <FaSpinner style={{ animation: "spin 1.5s linear infinite" }} />
              ) : (
                <FaSearch />
              )}
            </Button>
          </div>
          {/* Dropdown with search results */}
          {searchResults.length > 0 && (
            <Dropdown.Menu show className="mt-2 bg-dark text-light border">
              {searchResults.map((profile, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleSelectProfile(profile.profileURL)}
                  className="bg-dark text-light d-flex align-items-center"
                >
                  <img
                    src={profile.profilePicture}
                    alt=""
                    className="rounded-circle me-2"
                    style={{ width: "40px" }}
                  />
                  {profile.fullName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          )}
        </Form.Group>

        <Form.Group controlId="formPhone" className="mb-5 position-relative">
          <Form.Label className="mb-0" style={style.formLabel}>
            Phone Number
          </Form.Label>
          <div style={{ position: "relative" }}>
            <Form.Control
              type="tel"
              name="phone"
              placeholder="+1 (555) 555-5555"
              value={formState.phone || ""} // Bind to formState
              onChange={handleInputChange} // Update formState on change
              className="custom-input bg-transparent text-light border-bottom border-0 rounded-0 px-0"
              style={{
                height: "60px",
                fontSize: "1.2rem",
                color: "white",
                opacity: 0.7,
                paddingRight: "40px", // Leave space for the icon
              }}
              required
            />
            {/* Status Icon */}
            <div
              className="position-absolute"
              style={{
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                opacity: formState.phone?.length >= 10 ? 1 : 0, // Show only if phone is valid
                transition: "opacity 0.2s ease",
                pointerEvents: "none",
              }}
            >
              <FaCheckCircle color="#3cb8e7" size={20} />
            </div>
          </div>
          {/* Privacy Copy */}
          <p
            style={{
              fontSize: "0.8rem",
              color: "white",
              opacity: 0.6,
              marginTop: "10px",
            }}
          >
            We value your privacy. Your phone number will never be shared with
            third parties.
          </p>
        </Form.Group>

        <Button
          variant="btn-link"
          type="submit"
          className="w-100 rounded-pill text-info"
          style={{ fontSize: "1.2rem", padding: "15px" }}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Sending...
            </>
          ) : (
            "Continue"
          )}
        </Button>
      {/* Display QR Code and SMS options after successful submission */}
      {token && (
          <div className="mt-4 text-center">
            <Button
              variant="link"
              className="text-info"
              onClick={() => setShowQRModal(true)}
              style={{ fontSize: "1rem" }}
            >
              <FaQrcode className="me-2" />
              View QR Code
            </Button>
            <Button
              variant="link"
              className="text-info"
              onClick={handleSendSms}
              style={{ fontSize: "1rem" }}
            >
              <FaSms className="me-2" />
              Send as Text Message
            </Button>
          </div>
        )}
      </Form>

      {/* QR Code Modal */}
      <Modal
        show={showQRModal}
        onHide={() => setShowQRModal(false)}
        style={{backgroundColor: 'rgba(0,0,0,0.8)'}}
        contentClassName="bg-black text-white"
        dialogClassName="modal-dialog-centered"
        >
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="text-center">Scan QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center border-0">
          {token && (
            <QRCodeCanvas
              value={`https://m.intheroom.social?t=${token}`}
              size={200}
              bgColor="#000000"
              fgColor="#ffffff"
              level="L"
            />
          )}
          <p className="mt-3">Scan this code to sign into the app</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button style={{width: '100%'}} variant="info btn-lg" onClick={() => setShowQRModal(false)}>
            close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

const style = {
  formLabel: {
    fontSize: "1rem",
    color: "white",
    opacity: 0.7,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
};

export default ContactForm;
