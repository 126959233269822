import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Blog from './pages/Blog';
import Home from './pages/Home';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import WhitePaper from './pages/WhitePaper';
import Partner from './pages/Partner';
import Form from './pages/Form';
import LinkedInSearchPage from './pages/LinkedinSearch';
import { LinkedInProvider } from './context/linkedin-context'; // Import context
import PageNotFound from './pages/PageNotFound';

import './assets/fonts/Univers/style.css';
import './assets/fonts/neue-plak-webfont/style.css';
import './App.css';

function App() {
  return (
    <LinkedInProvider>
      <Router>
        <div className="App">
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/whitepaper" element={<WhitePaper />} />
              <Route path="/partner" element={<Partner />} />
              <Route path="/sign-up" element={<Form />} />
              <Route path="/search" element={<LinkedInSearchPage />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </LinkedInProvider>
  );
}

export default App;
