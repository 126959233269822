import React from "react";
// import Header from "../components/Header/Header";
import Meta from "../components/Meta";

const Terms = () => {
  return (
    <>
      <Meta
        title="In The Room | Terms & Conditions"
        url="https://www.intheroom.social/terms"
      />
    <div>
      {/* <Header /> */}
      <div className="text-container my-5 px-5 px-md-0">
        <p className="text-center mb-0"><small>June 19, 2024</small></p>
        <h1 className="text-center mb-5">
          <b>Terms and Conditions</b>
        </h1>

        <p>
          <b>Purpose of the Terms and Conditions:</b> These Terms and Conditions
          ("Terms") govern your use of the "In the Room" mobile application
          ("the App"). By using the App, you agree to comply with these Terms,
          which constitute a binding agreement between you and "In the Room"
          ("we," "our," or "us").
        </p>

        <p>
          <b>Overview of the App's Functionality:</b> "In the Room" is designed
          to help users meet and connect with others at events such as
          conferences, meetups, and venues like bars, clubs, and lounges. The
          App provides real-time geolocation features to show users others in
          their immediate vicinity, integrates with social media profiles
          (Instagram, LinkedIn), and allows sharing of user profiles with others
          in the area.
        </p>

        <h2>Acceptance of Terms</h2>

        <p>
          <b>User Agreement to These Terms:</b> By accessing or using the App,
          you agree to be bound by these Terms. If you do not agree with any
          part of these Terms, you must not use the App.
        </p>

        <p>
          <b>Changes to Terms and Notification:</b> We may modify these Terms at
          any time. We will notify you of significant changes by posting the
          updated Terms on the App and updating the effective date. Your
          continued use of the App after the effective date constitutes your
          acceptance of the updated Terms.
        </p>

        <h2>Account Registration</h2>

        <p>
          <b>Eligibility Criteria:</b> To use the App, you must be at least 16
          years old. By registering for an account, you represent and warrant
          that you meet this age requirement.
        </p>

        <p>
          <b>Account Creation and Accuracy of Information:</b> You must provide
          accurate and complete information when creating an account. You are
          responsible for maintaining the accuracy of this information.
        </p>

        <p>
          <b>User Responsibility for Account Security:</b> You are responsible
          for maintaining the confidentiality of your account credentials and
          for all activities that occur under your account. You agree to notify
          us immediately of any unauthorized use of your account.
        </p>

        <h2>Use of the App</h2>

        <p>
          <b>Permitted Uses of the App:</b> You may use the App for lawful
          purposes and in accordance with these Terms.
        </p>

        <p>
          <b>Prohibited Activities:</b> You agree not to:
        </p>
        <ul>
          <li>
            Misuse the App or attempt to gain unauthorized access to the App or
            its related systems or networks.
          </li>
          <li>
            Engage in any activity that could damage, disable, or impair the
            App.
          </li>
          <li>Use the App for any unlawful or fraudulent purpose.</li>
        </ul>

        <p>
          <b>User Conduct and Obligations:</b> You agree to comply with all
          applicable laws and regulations when using the App. You are solely
          responsible for your conduct and any content you submit or share
          through the App.
        </p>

        <h2>Geolocation Services</h2>

        <p>
          <b>Use of Real-Time Geolocation Data:</b> The App uses real-time
          geolocation data to show your location and the location of other users
          in your vicinity.
        </p>

        <p>
          <b>User Consent for Location Tracking:</b> By using the App, you
          consent to the collection and use of your geolocation data as
          described in our Privacy Policy.
        </p>

        <h2>Social Media Integration</h2>

        <p>
          <b>Linking Social Media Profiles:</b> You may link your social media
          profiles (e.g., Instagram, LinkedIn) to your account to enhance your
          networking experience.
        </p>

        <p>
          <b>Use of Social Media Data Within the App:</b> The App uses data from
          your linked social media profiles to facilitate connections and
          personalize your experience.
        </p>

        <h2>User Content</h2>

        <p>
          <b>Ownership and Responsibility for User-Generated Content:</b> You
          retain ownership of any content you submit or share through the App.
          You are solely responsible for such content.
        </p>

        <p>
          <b>License Granted to the App for Use of Content:</b> By submitting or
          sharing content through the App, you grant us a worldwide,
          non-exclusive, royalty-free license to use, display, reproduce, and
          distribute your content in connection with the App.
        </p>

        <p>
          <b>Prohibited Content and Activities:</b> You agree not to submit or
          share content that is illegal, harmful, or violates the rights of
          others. We reserve the right to remove any content that violates these
          Terms.
        </p>

        <h2>Privacy</h2>

        <p>
          <b>Link to the App’s Privacy Policy:</b> For information on how we
          collect, use, and protect your personal data, please refer to our{" "}
          <a href="/privacy-policy">Privacy Policy</a>.
        </p>

        <p>
          <b>Summary of Data Collection and Use:</b> We collect and use your
          personal data to provide and improve the App's services, as described
          in our Privacy Policy.
        </p>

        <h2>Payments and Subscriptions</h2>

        <p>
          <b>Description of Paid Features and Subscriptions:</b> Some features
          of the App may require payment or a subscription. Details of these
          features and their costs will be provided within the App.
        </p>

        <p>
          <b>Payment Terms and Conditions:</b> By purchasing any paid features
          or subscriptions, you agree to our payment terms, which include
          providing accurate billing information and timely payment of all fees.
        </p>

        <p>
          <b>Refund Policy:</b> All payments are non-refundable unless otherwise
          stated. We reserve the right to issue refunds at our sole discretion.
        </p>

        <h2>Intellectual Property</h2>

        <p>
          <b>Ownership of App Content and Intellectual Property:</b> We own all
          rights, title, and interest in and to the App, including all content,
          features, and functionality. This includes all intellectual property
          rights.
        </p>

        <p>
          <b>User License to Use the App:</b> We grant you a limited,
          non-exclusive, non-transferable, and revocable license to use the App
          for personal, non-commercial purposes, subject to these Terms.
        </p>

        <h2>Third-Party Services</h2>

        <p>
          <b>Links to Third-Party Websites and Services:</b> The App may contain
          links to third-party websites and services. These links are provided
          for your convenience, and we are not responsible for the content or
          practices of these third parties.
        </p>

        <p>
          <b>Disclaimer of Responsibility for Third-Party Content:</b> We do not
          endorse or assume responsibility for any third-party content or
          services linked through the App. You access and use third-party links
          at your own risk.
        </p>

        <h2>Disclaimers and Limitation of Liability</h2>

        <p>
          <b>App Provided "As Is" Without Warranties:</b> The App is provided on
          an "as is" and "as available" basis, without any warranties of any
          kind, either express or implied.
        </p>

        <p>
          <b>Limitation of Liability for App Use:</b> To the fullest extent
          permitted by law, we disclaim all liability for any damages arising
          out of or related to your use of the App.
        </p>

        <h2>Indemnification</h2>

        <p>
          <b>User Agreement to Indemnify the App:</b> You agree to indemnify and
          hold us harmless from any claims, damages, losses, liabilities, and
          expenses arising out of or related to your use of the App or violation
          of these Terms.
        </p>

        <h2>Termination</h2>

        <p>
          <b>Terms Under Which Accounts Can Be Terminated:</b> We may terminate
          or suspend your account at any time, with or without cause or notice,
          for any reason, including if you violate these Terms.
        </p>

        <p>
          <b>Effect of Termination on User Access:</b> Upon termination, your
          right to use the App will immediately cease. We reserve the right to
          delete your account and any content you have submitted.
        </p>

        <h2>Governing Law</h2>

        <p>
          <b>Jurisdiction and Governing Law for Disputes:</b> These Terms are
          governed by and construed in accordance with the laws of the
          jurisdiction in which we are based, without regard to its conflict of
          law principles. You agree to submit to the exclusive jurisdiction of
          the courts located in that jurisdiction for the resolution of any
          disputes.
        </p>

        <p>
          <b>Dispute Resolution Process:</b> Any disputes arising out of or
          related to these Terms or your use of the App will be resolved through
          binding arbitration in accordance with the rules of the American
          Arbitration Association. Each party will bear its own costs and
          expenses.
        </p>

        <h2>Changes to Terms and Conditions</h2>

        <p>
          <b>Process for Updating Terms:</b> We may update these Terms from time
          to time. We will notify you of any significant changes by posting the
          new Terms on the App and updating the effective date.
        </p>

        <p>
          <b>Notification of Changes:</b> We will notify you of any significant
          changes to these Terms by posting a notice within the App or by other
          means as appropriate.
        </p>

        <h2>Contact Us</h2>

        <p>
          <b>Contact Information for Terms-Related Inquiries:</b> If you have
          any questions or concerns about these Terms or our data practices,
          please contact us at:
        </p>
        <p>
          Email:{" "}
          <a href="mailto:hello@intheroom.social">hello@intheroom.social</a>
        </p>

        <p>
          By using "In the Room," you acknowledge that you have read and
          understood these Terms and agree to be bound by them.
        </p>
      </div>
    </div>
    </>
  );
};

export default Terms;
