import React from 'react';

const SlidingView = ({ children, isActive, isSlidingOut }) => {
  const slideClass = isActive
    ? 'slide-in'
    : isSlidingOut
    ? 'slide-out'
    : '';

  return (
    <div className={`view ${slideClass}`}>
      {children}
    </div>
  );
};

export default SlidingView;
