import React from 'react';
import logo from '../../assets/images/logo.svg';
import ycLogo from '../../assets/images/yc-logo.png';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

const MainView = ({ onRequestAccess }) => (
  <>
    <header className="header">
      <img src={logo} alt="Logo" className="logo" />
      {/* <img src="logo.svg" alt="Logo" class="logo" /> */}
    </header>
    <main className="content">
      <div className="title-subtitle-wrapper">
        <h1 className="title">
          CLICK & VIBE <br />
          <span className="highlight">IN THE ROOM</span>
        </h1>
        <p className="subtitle">
          {/* Real-time connections in physical spaces */}
          {/* Social  */}
          See who's actually in the room with you<br></br>—right now, in real life
          {/* <span className="dashed-text" data-tooltip-id="my-tooltip" data-tooltip-content="In Real Life">IRL */}
          {/* <FontAwesomeIcon className='question-mark' icon={faQuestionCircle} /> */}
        {/* </span> */}
        </p>
        <Tooltip place="bottom" type="dark" id="my-tooltip" />
      </div>
      <div className="access-button-wrapper">
        <button className="access-button" onClick={onRequestAccess}>REQUEST ENTRY</button>
        <a href='https://bookface.ycombinator.com/company/12062' rel="noreferrer" target='_blank'>
          <img src={ycLogo} alt="Y Combinator Logo" className="yc-logo" />
        </a>
      </div>
    </main>
  </>
);

export default MainView;