import React from 'react';
import logo from '../assets/images/logo.svg';
import Meta from '../components/Meta';
import 'react-tooltip/dist/react-tooltip.css'


// import SimpleHeader from '../components/Header/SimpleHeader'


const PageNotFound = () => {
  return (
    <>
      <Meta
        title="Connect with others In The Room - IRL"
        content="In The Room is an in-person app for connecting people in physical spaces. Perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public places."
        url="https://www.intheroom.social"
      />
      {/* <SimpleHeader /> */}
      <div className={`container`}>
        {/* Main View */}
        <header className="header">
          </header>
        <div className={`view slide-in`}>
          <main className="content">
            <div className="title-subtitle-wrapper" style={{ top: '45%' }}>
              <h1 className="title">
                <span className="highlight">404</span>
              </h1>
              <p className="subtitle">It's not you - It's Us<br /> the page is not found
              </p>
            </div>
            <div className="access-button-wrapper">
            <header className="header">
                <a href="/">
                  <img src={logo} alt="Logo" className="logo" style={{width: '30px'}} />
                </a>
            </header>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;