import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import faviconImage from '../../assets/images/favicon.ico'; // Path to your favicon image
import coverImage from '../../assets/images/in-the-room-cover.png';


const getFullImageUrl = (relativePath) => {
  const { protocol, hostname, port } = window.location;
  const domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  return `${domain}${relativePath}`;
};

const Meta = ({
  title = 'Connect with people around you | In The Room',
  description = 'In The Room is an in-person networking and connectivity app for helping people connect in physical spaces. Perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public places.',
  keywords = 'networking, nearby, local social, app, hyper-local',
  author = 'In The Room, Inc.',
  image = coverImage,
  url = window.location.href,
}) => {
  const fullImageUrl = getFullImageUrl(image);

  useEffect(() => {
    const favicon = document.querySelector("link[rel~='icon']") || createFavicon();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.src = faviconImage;
    img.crossOrigin = "Anonymous";

    canvas.width = 32; // Adjust to your favicon size
    canvas.height = 32;

    let hue = 45; // Start hue at 45deg

    function drawRoundedRect(ctx, x, y, width, height, radius) {
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    }

    function updateFavicon() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw black rounded rectangle
      ctx.fillStyle = "black";
      drawRoundedRect(ctx, 0, 0, canvas.width, canvas.height, 8); // Radius of 5 for rounded corners
      ctx.fill();

      // Apply hue-rotate and draw the image
      ctx.filter = `hue-rotate(${hue}deg)`;
      ctx.drawImage(img, 4, 4, 24, 24); // Adjust image position and size within the rounded background

      // Update the favicon
      favicon.href = canvas.toDataURL("image/png");
      hue = (hue + 1) % 360; // Increment hue for rotation
      requestAnimationFrame(updateFavicon); // Keep animating
    }

    img.onload = () => updateFavicon();

    function createFavicon() {
      const link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
      return link;
    }
  }, []);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      {/* <link rel="icon" href="/favicon.ico" /> */}

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={fullImageUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={fullImageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={author} />
    </Helmet>
  );
};

export default Meta;
