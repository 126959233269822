import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const TextView = ({ onRequestAccess, title }) => (
  <>
    <main className="content">
      <h2 className="descriptive-title col-10">{title}</h2>
      <div className="access-button-wrapper">
        <button className="access-button circle" onClick={onRequestAccess}>
          <FontAwesomeIcon icon={faArrowRight} className="icon" size='2xl' />
        </button>
      </div>
    </main>
  </>
);

export default TextView;